import React, { useEffect, useState, useContext } from 'react';  
import Accordion from 'react-bootstrap/Accordion'; 
import {  Link, useParams } from "react-router-dom";
import { Context } from "../../Store"; 
import { useWeb3React, Web3ReactHooks } from "@web3-react/core";
import { useNativeBalance } from "../../hooks/useNativeBalance";
import { parseBigNumberToFloat } from "../../utils/formatters";
//import web3 from "web3";
import contractAbi from  "../../contract/GenericERC20.json"; 
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import ConnectWallet from '../Account/ConnectWallet';
 

var numeral = require('numeral');





const IntroBoard = () => {


    
    const [state, dispatch] = useContext(Context);
    const cctPrice =  state.bnbUsd / 480; //state.cctRate;
    const cbtPrice =  state.bnbUsd/state.cbtRate;
    const cct = state.cctBalance;
    const { account, provider, chainId } = useWeb3React();
    const balance = useNativeBalance(provider, account);
    const [tokenName, setTokenName] = useState('BNB');
    const [cctToken, setToken]  = useState(0);
    let params = useParams();  
    const ref = params.ref;

     
    useEffect(() => {

       
        if(chainId !== 56){
            setTokenName('ETH');
        }
  
        
    })
  

 return (
 
    <> 

<section className="banner">
    <div className="shape right" />
    <div className="container big">
      <div className="row">
        <div className="col-xl-6 col-md-12">
          <div className="banner__left">
            <div className="block-text">
              <h2 className="heading">
                 {state.tokenName} is {" "}
                <span className="s1 arlo_tm_animation_text_word" /> <br />
                
              </h2>
              <p className="desc">
              Revolutionize your crypto trading with {state.tokenName}
              </p>
              {
                  state.isLoggedIn ? <>
                    <Link to='/swap' className="action-btn">
                      <span>Swap</span>
                    </Link>
                  </>:<></>
              }
            </div>
            <div className="pay">
              <h6>METEGAN DEX</h6>
              <div className="list">
                <p>Decentralize on :</p>
                <ul>
                  <li>
                    BEP20
                  </li>
                   
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="banner__right">
            <div className="image">
              <img src="assets/images/layouts/banner.png" alt="" />
            </div>
            
            <div className="owner">
              <div className="image">
                <img src="assets/images/layouts/portfolio.png" alt="" />
              </div>
              <div className="content">
                <h5>METEGAN </h5>
                <p>@MET</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
      

                  
              
 
    </>
 )



}


export default IntroBoard;
