import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";  
import ConnectWallet from '../Account/ConnectWallet';


var numeral = require('numeral');



const Admin = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const contractAddress = state.tokenContract;
    //const { switchNetwork, chainId } = useChain();
    
    
	  const userAddress = state.walletAddress;
     
    const [metToSell, setMetToSell] = useState(2000);
    const sellCharge = 0.003;
    
    const [bnbToGet, setBnbToGet] = useState(0);
     
    const[ ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
    
    const [showButton, setShowButton] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [serverInfo, setServerInfo] = useState("");
    const [serverSellInfo, setServerSellInfo] = useState(""); 
    
    const [refBonus, setRefBonus] = useState(0);
    const { account, provider } = useWeb3React();
    const bnbValue = state.metBalance / state.metRate;
    const [walletIsConnected, setWalletIsConnected] = useState(false);
    const [contractBalance, setContractBalance] = useState(0.00); 

  useEffect(() => {
    if(state.isLoggedIn){  setWalletIsConnected(true); }
  },[state.isLoggedIn]);
 

function calculateSell(inputAmount){
  

  if(inputAmount >= 1){

    setMetToSell(inputAmount)
      setShowButton(true);
  }else{
      setShowButton(false);
  }
 
  let bnb = (1/state.metRate) * inputAmount;
  setMetToSell(inputAmount);
  setBnbToGet(bnb);


  setToggleAlert(false);


}

async function updateBalance(){

    let newBal = await MyFunction.balanceOf(userAddress);
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
    
}

 
async function getContractBalance(){
  let newBal = await MyFunction.contractBalance(state.tokenContract, provider);
  
  setContractBalance(newBal);
  
}
 

async function sellMet(){

 
  if(metToSell < 0.002){
    
      setErrorMessage('Minimum of 0.003 bnb is allowed');
      setToggleAlert(true);
      return false;

  }

  setToggleAlert(false); 
 setIsProcessing(true);
 setServerInfo('...connnecting to wallet')
 



 let metAmount = ethers.utils.parseEther(metToSell);

    
  const signer = provider.getSigner();
  const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
  const overrides = {value: ethers.utils.parseEther("0.0003")} 

  dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

  try {

    const tx = await contract.sell(overrides, account, metToSell); 
    // Waiting for the transaction to be mined
    const receipt = await tx.wait();
        
    if(receipt.status == 1){  
      setIsProcessing(false);
      dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 
    }else{
       setErrorMessage('Sorry, unable to process at the moment');
        setToggleAlert(true);

        dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
        setIsProcessing(false);
    }


    updateBalance();   


}
catch(err){

setErrorMessage("Try again.");
console.log(err);

setToggleAlert(true);
setIsProcessing(false);

}

              
}



 return (
 
    <>
  
  <div className="roadmap-box right">
                  <div className="time">Contract Owner</div>
                  <div className="content">
                  <h5 className="title">Contract BNB: {contractBalance}
                    | <button className="btn btn-sm" onClick={() => getContractBalance()}>Refresh</button>
                    
                  </h5>
                  <a href='https://bscscan.com/token/0xc0774c5fb8ee0914d5546ae3a3bb892c349c5261#writeContract'>Withdraw BNB</a>
                     
                      {/* <div className="text">

                          <div className="input-group mb-3">
                                                  
                              <input type="number" className="form-control" placeholder="20" onChange={(e) => calculateSell(e.target.value)} />
                              
                          </div>
                          
                          <div className='input-group mb-3'>
                          
                              <p className='mute'>
                                  You will receive { numeral(bnbToGet).format("0.0000") } BNB
                              </p>
                            
                              
                          </div>
                          <div className='input-group mb-3'>
                            <p className='mute'>
                                  You will be charge {numeral(sellCharge).format("0.0000")} MET
                            </p>
                          </div>
                        


                          <Myalert message={ErrorMessage} toggle={toggleAlert} />


                          {

                          showButton == true ? <>

                                {
                                    isProcessing == true ? <>

                                            <Myalert message={serverInfo} toggle={true} />
                                              

                                    </>: <>
                                        <button className="fs-14 form-control" onClick={() => sellMet()}>Sell DGT</button>

                                    </>
                                }
                                
                            </>: <> </>
                          } 


                            
                      </div> */}
                  </div>
                </div>
       
    </>
 )



}


export default Admin;
