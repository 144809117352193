 
import contractAbi from  "../contract/GenericERC20.json";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
var numeral = require('numeral');
 


async function balanceOf(address, provider, contractAddress){

  
  let tokenContractAddress = contractAddress;
  let uni;
  const tokenContract = new ethers.Contract(
  tokenContractAddress,
  contractAbi,
  provider
  );
  uni = await tokenContract.balanceOf(address);
  let bal = ethers.utils.formatEther(uni, 18);

  console.log(bal);
   
  return bal;

 }


 async function name(address, provider, contractAddress){

 

  let tokenContractAddress = contractAddress;
  let name;
  const tokenContract = new ethers.Contract(
  tokenContractAddress,
  contractAbi,
  provider
  );
  name = await tokenContract.name();
    
  return name;

 }


 async function symbol(address, provider, contractAddress){

 

  let tokenContractAddress = contractAddress;
  let name;
  const tokenContract = new ethers.Contract(
  tokenContractAddress,
  contractAbi,
  provider
  );
  name = await tokenContract.symbol();
    
  return name;

 }

 async function totalSupply(address, provider, contractAddress){

 

  let tokenContractAddress = contractAddress;
  
  const tokenContract = new ethers.Contract(
  tokenContractAddress,
  contractAbi,
  provider
  );
  const supply = await tokenContract.totalSupply();
    
  return supply;

 }

 async function contractBalance(contractAddress,provider){

 

  let tokenContractAddress = contractAddress;
  
  const tokenContract = new ethers.Contract(
  tokenContractAddress,
  contractAbi,
  provider
  );
  const balance = await tokenContract.bnbBalanceOf();
  let bal = ethers.utils.formatEther(balance, 18);
  return bal;

 }

  


 async function metRate(provider, contractAddress){
 
  
  let tokenContractAddress = contractAddress;
  let uni;
  const tokenContract = new ethers.Contract(
  tokenContractAddress,
  contractAbi,
  provider
  );
  const myRate  = await tokenContract.metRate();
  
         
   return myRate; 
  
 }

 
 
 async function bnbToUsd() {
  const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD");
  var data = await response.json();
  var price = data.USD;
  var price1 = price.toFixed(2); 
  return price1;
  
}

async function ethToUsd() {
  const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
  var data = await response.json();
  var price = data.USD;
  var price1 = price.toFixed(2); 
  return price1;
  
}

   
  
   
  export const MyFunction = {
  
    balanceOf, 
    metRate,
    bnbToUsd,
    ethToUsd, 
    name,
    totalSupply,
    symbol,
    contractBalance
 
  }

 

 

  



   