import React, { useEffect, useState } from 'react'; 
import { Link } from "react-router-dom";

const Faq = (props) => {

 

 return (
 
    <>


<section className="faq s3">
  <div className="shape" />
  <div className="container">
    <div className="row">
      <div className="col-xl-6 col-md-12">
        <div className="block-text">
           
          <h3 className="heading">
          Dive into the Community
          </h3>
          
        </div>
        <div className="faq__main flat-tabs">
          <ul className="menu-tab">
             
            <li className="active">
              {" "}
              <h6 className="fs-14">Metegan</h6>
            </li>
            
          </ul>
          <div className="content-tab">
            <div className="content-inner">
              <div className="flat-accordion">
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>01.</span> Trade Sharing {" "}
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                     
Share your successful trades or strategies with a community of traders, fostering a collaborative trading environment.

                    </p>
                  </div>
                </div>
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>02.</span> Leaderboards
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                    Engage in friendly competition by ranking on leaderboards based on trading performance or other metrics. 
                    </p>
                  </div>
                </div>
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>03.</span> Community Insights
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                    Tap into community-driven insights, predictions, and strategies, enhancing your trading knowledge. 
                    </p>
                  </div>
                </div>
                <div className="flat-toggle">
                  <h6 className="toggle-title">
                    <span>04.</span>Privacy Controls
                    <span className="icon-plus" />
                  </h6>
                  <div className="toggle-content">
                    <p>
                    Choose what you share and with whom, ensuring your trading strategies and data remain as private as you want them to be.
                    </p>
                  </div>
                </div>
              </div>
            </div>
             
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-12">
        <div className="image">
          <img src="assets/images/layouts/img-faq.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

  
 

    </>
 )



}


export default Faq;
