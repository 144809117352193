import React  from 'react'; 
import  { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home"; 
import Store from "./Store"; 
import SwapPage from './pages/SwapPage'; 
import SystemPage from './pages/SystemPage'; 
import Staking from './pages/Staking';


const App = () => {
   
    
 return (
     <>
     <Store >
            <Router>
                <Routes>
                <Route exact path="/" element={<Home/>}/>  
                    <Route exact path="/swap" element={<SwapPage />} /> 
                    <Route exact path="/stake" element={<Staking />} /> 
                    <Route exact path="/system" element={<SystemPage/>}/>  
                    <Route path="*" element={<Home/>}/>
                    <Route exact path=":ref" element={<Home/>}/>
                    
                </Routes>
            </Router>
     </Store>
      
     </>
 );
}

export default App;
