import React, { useEffect, useState, useContext } from 'react'; 
import {useNavigate, Link} from "react-router-dom";
import { Contract, ContractInterface, Signer, ethers } from "ethers";
import contractAbi from "../../contract/GenericERC20.json";
import {Myalert} from "../../helpers"; 
import { Context } from '../../Store';
import ACTIONS from "../../constants/Actions";
import {MyFunction} from "../../func/Funcs";   
import { useWeb3React } from "@web3-react/core";
import ConnectWallet from '../Account/ConnectWallet';   


var numeral = require('numeral');



const BuyMet = () => {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const contractAddress = state.tokenContract;
    //const { switchNetwork, chainId } = useChain();
    
    
	  const userAddress = state.walletAddress;
    const [metToBuy, setMetToBuy] = useState(2000);
    const [bnbCharge, setBnbCharge] = useState(0); 
    const[ ErrorMessage, setErrorMessage] = useState(""); 
    const [toggleAlert, setToggleAlert] = useState(false); 
    const [showBuyButton, setShowBuyButton] = useState(false);
    
    const [isProcessing, setIsProcessing] = useState(false);
    const [serverInfo, setServerInfo] = useState("");
    const [serverSellInfo, setServerSellInfo] = useState("");
    const [refBonus, setRefBonus] = useState(0);
    const { account, provider } = useWeb3React();
    const bnbValue = state.metBalance / state.metRate;
    const [walletIsConnected, setWalletIsConnected] = useState(false);


    useEffect(() => {

      
      if(state.isLoggedIn){  setWalletIsConnected(true); }

       
  },[state.isLoggedIn]);


function calculateBuy(inputAmount){


        if(inputAmount >= 16666){

          setMetToBuy(inputAmount)
          setShowBuyButton(true);
      }else{
          setShowBuyButton(false);
      }

    

    //let bnb = (1/state.metRate) * inputAmount;

    let bnb = 0.000006 * inputAmount;
     
    setMetToBuy(inputAmount);
    setBnbCharge(bnb);
     
    setToggleAlert(false);

}
 

async function updateBalance(){

    let newBal = await MyFunction.balanceOf(userAddress);
    dispatch({type: ACTIONS.UPDATE_BALANCE, payload: newBal});
    
}

async function buyMet(){

 
    if(metToBuy < 16666){
      
        setErrorMessage('Minimum of 16666 MET is allowed');
        setToggleAlert(true);
        return false;

    }
 
    setToggleAlert(false); 
   setIsProcessing(true);
   setServerInfo('...connnecting to wallet')



   let amt = numeral(bnbCharge).format("0.0000");

    
   const signer = provider.getSigner();
   const contract = new ethers.Contract(state.tokenContract, contractAbi, signer );
    const overrides = {value: ethers.utils.parseEther(amt)} 
     
  
    dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});

    try {

      const tx = await contract.buy(overrides); 
     
      const receipt = await tx.wait();
          
      if(receipt.status == 1){  
        setIsProcessing(false);
          setErrorMessage('Transaction successful.');
          setToggleAlert(true);

        dispatch({type: ACTIONS.TOGGLE_TAB, payload: true}); 
      }else{
         setErrorMessage('Sorry, treansaction failed');
          setToggleAlert(true);

          dispatch({type: ACTIONS.TOGGLE_TAB, payload: false});
          setIsProcessing(false);
      }


      updateBalance();   


}
catch(err){

  setErrorMessage("Transaction cancel.");
  setToggleAlert(true);
  setIsProcessing(false);

}
 
   
                
}

 

 return (
 
    <> 
          <div className="roadmap-box">
                  <div className="time">Buy MET</div>
                  <div className="content">
                    <h5 className="title">1 MET = {numeral(1/state.metRate).format("0.000000")} BNB</h5>
                    <div className="text">

                            <div className="input-group mb-3">
                                                    
                                <input type="number" className="form-control" placeholder="16666" onChange={(e) => calculateBuy(e.target.value)} />
                                
                            </div>
                            <div className='input-group mb-3'>
                              <p>
                                 Rate: {numeral(bnbCharge).format("0.00000")} BNB = {numeral(metToBuy).format("0.0000")} MET
                              </p>
                                 
                                
                            </div>
                            <div className='input-group mb-3'>
                             
                                <p className='mute'>
                                    You will receive { numeral(metToBuy).format("0.0000") } MET
                                </p>
                               
                                
                            </div>
                            <div className='input-group mb-3'>
                              <p className='mute'>
                                    You will be charge {numeral(bnbCharge).format("0.00000")} BNB
                              </p>
                            </div>
                            <div className='input-group mb-3'>
                              <p className='mute'>
                                    Slepage Tolerance: 15%
                              </p>
                            </div>
                            
                          {
                              walletIsConnected != true  ? <>
                            
                            
                              <ConnectWallet inviteBy='1'/>
                              
                            </>:<>


                            <Myalert message={ErrorMessage} toggle={toggleAlert} />

                                {
                                      showBuyButton == true ? <>

                                          {
                                              isProcessing == true ? <>
                                                      <Myalert message={serverInfo} toggle={true} />
                                  
                                              </>: <>
                                                  <button className="fs-14 form-control" onClick={() => buyMet()}>Buy MET</button>
                                  
                                              </>
                                          }
                                          
                                      </>: <> </>
                                } 
                            
                            
                            
                            </>
                          
                          }
                           
                    </div>
                  </div>
                </div>
       
    </>
 )



}


export default BuyMet;
