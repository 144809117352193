 
const initialState = 
    {
        puk: 0,
        userId: 0, 
        isVerify: false,
        walletAddress: '', 
        userName: "",
        userEmail: "",
        userPhone: "",
        metBalance: 0, 
        isAdmin: false,
        isLoggedIn: false,
        canWithdraw: false, 
        refBalance: 0,
        metRate: 166667,
        bnbRate: 0,
        showTab: true,
        stake: {},  
        bnbUsd: 0,
        tokenContract: "0x37a11569Bd1a5c5B4b6e00F66Bb11e71D97A5eF3", 
        tokenName: "METEGAN", //"METEGAN",
        tokenSymbol: "MET",
        totalSupply: "10,000,000,000"
    };
 

export default initialState;
