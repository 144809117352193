 /**
  * 
  * This is an helper functions that let you login, check if user is login, and register user
  */
  import React from 'react';  
  import axios from 'axios';
 
  import {urllink} from "./../constants" 
 
   
 

async function UserIsLogin () {

  
  return false;
      

}
 

 
async function logMeIn (userEmail, userPassword, referral) {


    // let message = {}
    // let uri = urllink.login;
    // let puk = urllink.puk;
    // const requestOptions = {
    //     method: 'POST', 
    //     headers: {
    //         'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
    //         'Content-Type': 'application/json',
    //         'Access-Control-Allow-Headers':'*',
    //         'Access-Control-Allow-Origin': "http://localhost:3000"
            
    //       },
    //     body: JSON.stringify({ email: userEmail, password: userPassword, ref: referral })
    // };
   
    // const result = fetch(uri, requestOptions)
    // .then( async response => {
    //     const isJson = response.headers.get('content-type')?.includes('application/json');
    //     const data = isJson && await response.json();
    //     // check for error response
    //     if (!response.ok) {
    //         // get error message from body or default to response status
    //          message = { 
    //               status: 400,
    //               message: (data && data.message) || response.status
                  
    //           }
    //         return message;
            
    //     }

    //     return data;
            
    // })
    // .catch(error => {

    //   message = { 
    //     status: 400,
    //     message: 'something went wrong'
        
    // }
         
    //     return message;

    // });

    // return await result;

    const user = {
      email: userEmail,
      password: userPassword
    };

    const headers = {

        headers: {
               
                'Access-Control-Allow-Headers':'http:127.0.0.1:3000',
                'Access-Control-Allow-Origin': "http:127.0.0.1:3000",
                'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
                'Content-Type': 'application/json'
                
              },
    }

    let url = urllink.login;
   
   const response = await axios.post(url, user, headers)
      .then(res => {
        return res;
         
      });

    return response;



  }

 
 
  
async function signout  () {

  
  if(window.localStorage.getItem("@isLogin")){
    window.localStorage.removeItem('@isLogin'); 
    return ""; //<Redirect to="/signin" />
  }else{
    return  "";
  }
 
 
}
  
   
  export const Auth = {
  
    isLogin: UserIsLogin,
    login: logMeIn,
    logout: signout, 
     
 
  }

 

 

  



   